import { mapActions, mapGetters, mapMutations } from 'vuex'
import SeoForm from '@/components/seo-form/index.vue'

export default {
  name: 'education-action-plans-seo',
  computed: {
    ...mapGetters({
      currentItem: 'educationActionPlans/currentItem',
      seo: 'educationActionPlans/seo',
      seoLoading: 'educationActionPlans/seoLoading',
      updateSeoLoading: 'educationActionPlans/updateSeoLoading'
    })
  },
  components: {
    SeoForm
  },
  created () {
    if (!this.seo) {
      this.fetchData({ parentId: this.currentItem.id })
    }

	this.$on('event-save', () =>{
	  this.$refs['seoForm'].$emit('event-submit');
	});
  },
  methods: {
    ...mapActions({
      fetchData: 'educationActionPlans/GET_SEO',
      update: 'educationActionPlans/UPDATE_SEO'
    }),
    ...mapMutations({
      setSeo: 'educationActionPlans/SET_SEO'
    }),
    submit (formData) {
      formData.parentId = this.currentItem.id

      this.update(formData).then(() => {
        this.$toasted.success(this.$t('success_updated'));
      })
    }
  },
  destroyed () {
    this.setSeo(null)
  }
}
