import { mapActions, mapGetters } from 'vuex'

import ImageFile from '@/components/image-file/index.vue'

import { educationActionPlansFilesTableHead } from '@/modules/education/helpers/action-plans'

export default {
  name: 'table-files',
  props: {
    selectedItem: {},
    listLength: {}
  },
  data () {
    return {
      valid: false,
      showDialogFile: false,
      deletedItemFile: null,
      showDeletedDialogFile: false,
      payload: {
        name: '',
        src: '',
        sort: '',
        active: false
      },
      isLoading: false
    }
  },
  components: {
    ImageFile
  },
  computed: {
    educationActionPlansFilesTableHead,
    ...mapGetters({
      currentItem: 'educationActionPlans/currentItem',
      updateImageLoading: 'educationActionPlansContent/updateImageLoading',
      contentFileLoading: 'educationActionPlansContent/contentFileLoading',
      removeFileLoading: 'educationActionPlansContent/removeFileLoading'
    })
  },
  created () {
    this.editItemFile = this.selectedItem
  },
  methods: {
    ...mapActions({
      createFile: 'educationActionPlansContent/CREATE_CONTENT_FILE',
      updateFile: 'educationActionPlansContent/UPDATE_CONTENT_FILE',
      deleteFile: 'educationActionPlansContent/DELETE_CONTENT_FILE',
      updateImage: 'educationActionPlansContent/UPDATE_IMAGE'
    }),
    changeDialog (item = null, status = false, type = 'edit') {
      if (type === 'edit') {
        if (item) {
          Object.assign(this.payload, item)
        }
        this.showDialogFile = status
      } else if (type === 'delete') {
        this.deletedItemFile = item
        this.showDeletedDialogFile = status
      } else {
        this.resetPayload()
        this.payload.sort = Math.round(Math.random() * 100)
        this.showDialogFile = status
      }
    },
    resetPayload () {
      Object.keys(this.payload).forEach(e => {
        this.payload[e] = ''
      })
    },
    updateFileLocal (params) {
      this.payload.src = params.get('src')
    },
    removeItemFile () {
      this.deleteFile({
        contentId: this.selectedItem.id,
        actionPlanId: this.currentItem.id,
        id: this.deletedItemFile.id
      }).then(() => {
        this.showDeletedDialogFile = false
        this.$toasted.success(this.$t('success_deleted'))
      })
    },
    createRequestPayload () {
      const formData = new FormData()
      Object.keys(this.payload).forEach(e => {
        formData.append(e, this.payload[e])
      })
      formData.append('type_id', 2)
      formData.append('sort', this.payload.sort ? this.payload.sort : this.listLength + 1)
      formData.append('active', this.payload.active ? 1 : 0)
      formData.append('action_plan_id', this.currentItem.id)
      formData.append('content_id', this.selectedItem.id)
      if (this.payload.hasOwnProperty('id')) {
        formData.append('_method', 'patch')
      }
      return formData
    },
    submit () {
      this.$refs.form.validate()
      if (this.valid) {
        if (this.payload.hasOwnProperty('id')) {
          this.updateFile(this.createRequestPayload()).then(() => {
            this.showDialogFile = false
            this.$toasted.success(this.$t('success_updated'))
          })
        } else {
          this.createFile(this.createRequestPayload()).then(() => {
            this.showDialogFile = false
            this.$toasted.success(this.$t('success_created'))
          })
        }
      }
    }
  }
}
