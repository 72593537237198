import { mapActions, mapGetters, mapMutations } from 'vuex'
import { camelCase, debounce, snakeCase } from 'lodash'
import moment from 'moment'

import mixins from '@/mixins'
import validation from '@/mixins/validation'
import course from '@/mixins/course'

import MainEditor from '@/components/editor/index.vue'
import ImageFile from '@/components/image-file/index.vue'
import SlugNameInput from '@/components/slug-name-input/index.vue';

import SelectUserPosition from '@/components/select-user-position';

export default {
  name: 'education-action-plans-general',
  mixin: [course, mixins, validation],
  data () {
    return {
      valid: false,
      search: null,
      searchInput: null,
      payload: {
        name: '',
        slug: '',
        locale: '',
        description: '',
        categoryId: '',
        countryId: '',
        statusId: '',
        tariffs: '',
        picture: '',
        cover: '',
        date: '',
        sort: 0,
        authors: [],
        alterText: '',
        title: '',
        trial: false,
        // key for get authors
        isAuthors: true
      }
    }
  },
  components: {
    MainEditor,
    ImageFile,
    SlugNameInput,
    SelectUserPosition
  },
  computed: {
    ...mapGetters({
      isLoading: 'educationActionPlans/isLoading',
      currentItem: 'educationActionPlans/currentItem',
      updateImageLoading: 'educationActionPlans/updateImageLoading',
      authors: 'user/speakers',
      setting: 'courses/setting',
      isLoadingAuthors: 'user/speakersLoading',
      profile: 'profile/profile',
      locales: 'profile/locales',
      categories: 'educationCategories/list',
      categoriesLoading: 'educationCategories/isLoading',
      tariffs: 'educationTariffs/list',
      tariffsLoading: 'educationTariffs/isLoading',
      accessCountries: 'profile/accessCountries'
    })
  },
  created () {
    if (this.currentItem) {
      this.setData()
      this.fetchAuthors({
        isAuthors: true,
      }).then(() => {
        this.setData()
      })
    } else {
      this.fetchAuthors({
        isAuthors: true,
      })
    }
    if (this.categories.length <= 0) this.fetchCategories()
    if (this.tariffs.length <= 0) this.fetchTariffs({ countryIds: this.payload.countryId })

	this.$on('event-save', () =>{
	  this.submit();
	});
  },
  methods: {
    ...mapActions({
      fetchCategories: 'educationCategories/GET_LIST',
      fetchTariffs: 'educationTariffs/GET_LIST',
      update: 'educationActionPlans/UPDATE',
      fetchCurrentItem: 'educationActionPlans/GET_LIST_ITEM',
      create: 'educationActionPlans/CREATE',
      fetchAuthors: 'user/GET_SPEAKERS',
      updateImage: 'educationActionPlans/UPDATE_IMAGE'
    }),
    ...mapMutations({
      setCountryIds: 'profile/SET_COUNTRY_IDS'
    }),

    onReloadUserPosition(){
        this.fetchAuthors({
            isAuthors: true,
        })
    },

    updateImageLocal (params) {
      params.append('action_plan_id', this.currentItem.id)
      this.updateImage(params).then(() => {
        this.$toasted.success(this.$t('success_updated'))
      })
    },
    changeCountry (e) {
      this.setCountryIds([e])
      this.fetchTariffs({ countryIds: this.payload.countryId })
      this.payload.tariffs = ''
    },
    formattedDate (date) {
      if (!date) return ''
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    // authors
    changeInputAuthors: debounce(function (val) {
      if (!val) return
      if (this.search?.full_name !== val) {
        this.fetchAuthors({
          isAuthors: true,
          keywords: val,
        })
      }
    }, 500),
    autoCompleteChanged (e) {
      this.payload.authors = e.map(e => e.id)
      this.search = e
    },
    // authors end //
    setData () {
      const transformObj = {}
      const item = this.currentItem
      Object.keys(item).forEach(e => {
        transformObj[camelCase(e)] = item[e]
      })
      Object.assign(this.payload, transformObj)
      this.payload.authors = this.currentItem.authors.data.map(e => e.user_position_id)
      this.payload.date = this.currentItem.date_at
      this.setCountryIds([this.currentItem.country.data.id])
      this.payload.tariffs = this.currentItem.tariffs.data.map(e => e.id)
      this.search = this.payload.authors
    },
    createRequestPayload () {
      const data = Object.assign(this.payload)
      const formData = new FormData()
      Object.keys(data).forEach(e => {
        if (e !== 'date') {
          formData.append(snakeCase(e), data[e])
        }
      })
      formData.append('date_at', this.formattedDate(this.payload.date))
      formData.append('trial', this.payload.trial ? 1 : 0)
      if (this.currentItem) {
        formData.append('_method', 'patch')
      }
      return formData
    },
    submit () {
      this.$refs.form.validate()
      this.$refs.observer.validate()

      if (this.valid) {
        if (this.currentItem) {
          this.update(this.createRequestPayload()).then(response => {
            this.fetchCurrentItem(response).then(() => {
              this.setData()
            })
            this.$toasted.success(this.$t('success_updated'));
          });

        } else {
          this.create(this.createRequestPayload()).then(response => {
            this.fetchCurrentItem(response).then(() => {
              this.setData()
            })
            this.$toasted.success(this.$t('success_created'));
		  });
        }
      }
    }
  }
}
