import { mapGetters, mapMutations } from 'vuex'

import General from '../general/index.vue'
import Seo from '../seo/index.vue'
import EducationContent from '../content/index.vue'

export default {
  name: 'education-action-plans-dialog-view',
  data () {
    return {
      dialog: true,
      activeTab: 'general',
    }
  },
  components: {
    General,
    Seo,
    EducationContent
  },
  computed: {
    ...mapGetters({
      currentItem: 'educationActionPlans/currentItem',
	  isLoading: 'educationActionPlans/isLoading',
	  updateSeoLoading: 'educationActionPlans/updateSeoLoading'
    }),
    disableTabs () {
      return !this.currentItem
    },
    tabs () {
      return [
        {
          name: 'General',
          slug: 'general',
          disabled: false,
          id: 1
        },
        {
          name: 'Steps',
          slug: 'content',
          disabled: !this.currentItem,
          id: 2
        },
        {
          name: 'SEO',
          slug: 'seo',
          disabled: !this.currentItem,
          id: 3
        }
      ]
    }
  },
  methods: {
    changeCloseDialog () {
      this.dialog = false
      this.$emit('close-dialog')
    },
    ...mapMutations({
      setContentList: 'educationActionPlansContent/SET_LIST'
    }),

	save(){
	  this.$refs['general'].$emit('event-save');
	}
  },
  destroyed () {
    this.setContentList([])
  }
}
