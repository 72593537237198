export function educationActionPlansFilesTableHead () {
  return [
    {
      text: 'ID',
      value: 'id'
    },
    {
      text: 'Name',
      value: 'name'
    },
    {
      text: 'Actions',
      value: 'actions'
    }
  ]
}

export function educationActionPlansTableHead () {
  return [
    {
      text: 'ID',
      value: 'id'
    },
    {
      text: 'Picture',
      value: 'picture'
    },
    {
      text: 'Name',
      value: 'name'
    },
    {
      text: 'Country',
      value: 'country'
    },
    {
      text: 'Status',
      value: 'status'
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    }
  ]
}

export function educationActionPlansContentTableHead () {
  return [
    {
      text: '№',
      value: 'index',
      sortable: false
    },
    {
      text: 'Name',
      value: 'name'
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    }
  ]
}
