import { mapActions, mapGetters, mapMutations } from 'vuex'
import { camelCase, debounce, snakeCase } from 'lodash'

import mixins from '@/mixins'
import validationMixin from '@/mixins/validation'

import ImageFile from '@/components/image-file/index.vue'
import MainEditor from '@/components/editor/index.vue'
import TableFiles from '../../components/table-files/index.vue'
import Draggable from 'vuedraggable'

import { educationActionPlansContentTableHead } from '@/modules/education/helpers/action-plans'

export default {
  name: 'education-action-plans-content',
  mixin: [mixins, validationMixin],
  data () {
    return {
      showRemoveDialog: false,
      removingItem: null,
      showContentDialog: false,
      selectedItem: null,
      valid: false,
      contents: [],
      payload: {
        typeId: '',
        name: '',
        type: 'text',
        sort: 1,
        link: '',
        src: '',
        active: true,
        description: ''
      }
    }
  },
  components: {
    ImageFile,
    Draggable,
    MainEditor,
    TableFiles
  },
  watch: {
    'list' () {
      this.contents = this.list
    }
  },
  computed: {
    ...mapGetters({
      editId: 'educationActionPlansContent/editId',
      setting: 'courses/setting',
      currentItem: 'educationActionPlans/currentItem',
      list: 'educationActionPlansContent/list',
      loading: 'educationActionPlansContent/loading',
      listLoading: 'educationActionPlansContent/listLoading'
    }),
    educationActionPlansContentTableHead
  },
  created () {
    if (this.list.length <= 0) {
      this.fetchList({
        parentId: this.currentItem.id
      }).then(() => {
        this.contents = this.list
      })
    } else {
      this.contents = this.list
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'educationActionPlansContent/GET_LIST',
      create: 'educationActionPlansContent/CREATE',
      remove: 'educationActionPlansContent/DELETE',
      update: 'educationActionPlansContent/UPDATE',
      updateSort: 'educationActionPlansContent/UPDATE_SORT'
    }),
    ...mapMutations({}),
    updateImageLocal (params) {
      this.payload.src = params.get('src')
    },
    // remove
    removeListItem () {
      this.remove(this.removingItem).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success(this.$t('success_deleted'))
      })
    },
    changeDialogDeleteItem (item) {
      this.showRemoveDialog = true
      this.removingItem = {
        parentId: this.currentItem.id,
        id: item.id
      }
    },
    // actions data
    setData (item) {
      const transformObj = {}
      Object.keys(item).forEach(e => {
        transformObj[camelCase(e)] = item[e]
      })
      Object.assign(this.payload, transformObj)
    },

    // popup change content
    resetPayload () {
      this.selectedItem = null
      Object.keys(this.payload).forEach(e => {
        this.payload[e] = ''
      })
      this.$refs.form.reset()
      this.payload.sort = 1
      this.payload.active = true
    },
    changeShowDialogContent (item = null, status = false, type = 'file') {
      this.payload.active = true
      this.payload.type = type

      if (item) {
        this.setData(item)
        this.payload.type = type
        this.selectedItem = item
      } else {
        this.selectedItem = null
        Object.keys(this.payload).forEach(e => {
          this.payload[e] = ''
        })
      }

      this.showContentDialog = status
      if (!status) {
        this.resetPayload()
      }
    },
    createRequestPayload () {
      const data = this.payload
      const formData = new FormData()
      Object.keys(data).forEach(e => {
        if (e !== 'typeId' && e !== 'active') {
          formData.append(snakeCase(e), data[e])
        }
      })
      formData.append('parent_id', this.currentItem.id)
      // if added new type you need take setting.content.types
      formData.append('type_id', '1')
      formData.append('active', this.payload.active ? 1 : 0)
      if (this.selectedItem) {
        formData.append('_method', 'patch')
      }
      return formData
    },
    submit () {
      this.$refs.form.validate()
      if (this.valid) {
        if (!this.selectedItem) {
          this.create(this.createRequestPayload()).then((response) => {
            this.selectedItem = response
            this.setData(response)
            this.$toasted.success(this.$t('success_created'))
          })
        } else {
          this.update(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_updated'))
            this.changeShowDialogContent()
          })
        }
      }
    },
    changeSort: debounce(function (e) {
      if (e.hasOwnProperty('moved')) {
        const sortsList = this.contents.map(e => e.id)
        const formData = {
          parentId: this.currentItem.id,
          contents: sortsList
        }
        this.updateSort(formData).then(() => {
          this.$toasted.success(this.$t('success_change_position'))
        })
      }
    }, 2000)
  }
}
